function getImageRect(sanityRef: string) {
  // example name of sanity's image ref: image-b21ad2f0b51961b3ef79a2d107cf493f75ecee7e-1725x1101-png
  const [width, height] = sanityRef.split("-")[2].split("x") || [0, 0];
  return { w: +width, h: +height };
}

function computedImageWidth(sanityRef: string, containerHeight: number) {
  const { w, h } = getImageRect(sanityRef);
  const heightRation = containerHeight / h;
  return Math.ceil(heightRation * w);
}

export { computedImageWidth };
