import React, { FC, HTMLProps } from "react";
import { SlideUp } from "components/Effects/SlideUp";

interface SlideUpWrapperProps extends HTMLProps<HTMLDivElement> {}

const SlideUpWrapper: FC<SlideUpWrapperProps> = ({ children }) => {
  return (
    <>
      <SlideUp className="flex h-32 w-full items-center space-x-3 rounded-xl bg-btq-card-bg-dark py-6 px-4 md:h-48 lg:p-14 xl:h-auto xl:w-96 xl:flex-col xl:space-x-0">
        {children}
      </SlideUp>
    </>
  );
};

export { SlideUpWrapper };
