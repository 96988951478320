import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";
import { MemberList } from "./MemberList";

import BgImg from "./background";

import { ANCHOR } from "constants/anchor";
import COLOR from "constants/design";
import type { LANGUAGE_CODE } from "website-metadata";
import { useMembers } from "./hooks";

interface MembersProps {
  locale: LANGUAGE_CODE;
}

const Members: FC<MembersProps> = ({ locale }) => {
  const { leadership } = useMembers(locale);

  return (
    <Section
      background={{
        color: COLOR.BACKGROUND_DARK,
        component: BgImg,
      }}
    >
      {/* leadership */}
      <div className="pt-6 pb-10 xl:py-14" id={ANCHOR.MEET_OUR_TEAM}>
        <Title className="items-center">
          <FormattedMessage id="TEAM_MEMBERS_LEADERSHIP_TITLE" />
        </Title>
        <MemberList leadership={leadership} />
      </div>
      {/* advisor */}
      {/* <div className="pb-9">
        <Title className="items-center">
          {formatMessage({ id: 'TEAM_MEMBERS_ADVISOR_TITLE' })}
        </Title>
        <CardContainer>
          {advisor.map((props) => (
            <MemberCard {...props} key={props.name} />
          ))}
        </CardContainer>
      </div> */}
    </Section>
  );
};

export default Members;
