import React from "react";
import type { PageProps } from "gatsby";
import { useIntl } from "react-intl";

import withI18n, { PageContextWithLocale } from "i18n/withI18n";
import { Layout } from "components/Layout";
import Members from "views/team/members";
import Hero from "views/team/hero";
import Map from "views/team/map";
import WeAreHiring from "views/team/weAreHiring";

import SEO from "components/seo";

const TeamPage = ({ pageContext, location }: PageProps) => {
  const { formatMessage } = useIntl();
  const { locale } = pageContext as PageContextWithLocale;

  return (
    <>
      <SEO
        title={formatMessage({ id: "TEAM_PAGE_TITLE" })}
        pathname={location.pathname}
        titlePrefix
        locale
      />
      <Layout>
        <Hero />
        <Members locale={locale} />
        <Map />
        <WeAreHiring />
      </Layout>
    </>
  );
};

export default withI18n(TeamPage);
