import { DEFAULT_LANGUAGE, MAPPED_LANG_TO_FORMATTED_LOCALE } from "../../constants/i18n";
import type { LANGUAGE_CODE } from "website-metadata";

export const getCmsLocaleContent = (
  contentObj: { [lang in LANGUAGE_CODE]?: unknown },
  locale: LANGUAGE_CODE
) => {
  return contentObj?.[locale] || contentObj?.[DEFAULT_LANGUAGE];
};

export const handleCmsDateWithLocale = (rawDate: string, locale: LANGUAGE_CODE) =>
  new Date(rawDate).toLocaleString(MAPPED_LANG_TO_FORMATTED_LOCALE[locale], {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
