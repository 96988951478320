import React from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";
import { Button } from "components/Button";
import { CustomLink } from "components/CustomLink";
import BgImg from "./background";

import { SLUG } from "constants/slug";
import COLOR from "constants/design";

const WeAreHiring = () => {
  return (
    <Section background={{ color: COLOR.BACKGROUND_DARK, component: BgImg }}>
      <div className="flex h-[40vh] flex-col items-start space-y-6 pb-16 pt-28 sm:h-[65vh] xl:space-y-8">
        <Title>
          <FormattedMessage id="TEAM_HIRING_TITLE" />
        </Title>
        <CustomLink href={SLUG.CAREER}>
          <Button>
            <FormattedMessage id="TEAM_VIEW_JOB_OPENING" />
          </Button>
        </CustomLink>
      </div>
    </Section>
  );
};

export default WeAreHiring;
