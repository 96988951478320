import React, { FC, HTMLProps, forwardRef } from "react";

import { useContainerHeight, usePhotos } from "./hooks";

import { SanityImage } from "components/SanityImage";
import { computedImageWidth } from "./utils";

interface PhotoWrapperProps extends HTMLProps<HTMLDivElement> {
  isLastPhoto: boolean;
  width: number;
}

const PhotoWrapper: FC<PhotoWrapperProps> = ({ children, isLastPhoto, width }) => {
  const style = isLastPhoto ? "" : "mr-4 sm:mr-7 xl:mr-9";

  return (
    <div
      style={{ width: `${width / 16}rem` }}
      className={`prevent-transform-flickering-for-safari h-full ${style}`}
    >
      {children}
    </div>
  );
};

const AutoPlayPhotos = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, className }, ref) => {
    const spaceForSmoothScroll = "pr-4 sm:pr-7 xl:pr-9";
    return (
      <div
        ref={ref}
        className={`prevent-transform-flickering-for-safari flex py-4 sm:py-0 ${spaceForSmoothScroll} ${className}`}
      >
        {children}
      </div>
    );
  }
);

interface PhotoProps {
  photos: Array<string>;
  height: number;
}

// this component requires contant height as reference, must assign a absolute value for it.
const Photo: FC<PhotoProps> = ({ photos, height }) => (
  <>
    {height ? (
      <AutoPlayPhotos
        // apply animation after calculating of width
        className={`${height ? "animate-auto-play-photos" : ""}`}
      >
        {photos.map((photo, index) => {
          const computedWidth = computedImageWidth(photo, height);
          return (
            <PhotoWrapper
              key={index}
              isLastPhoto={index + 1 === photos.length}
              width={computedWidth} // container should also have width
            >
              <SanityImage
                _ref={photo}
                alt=""
                // querying for diff sizes in diff devices
                height={height}
                width={computedWidth}
                className="h-full w-full object-cover" // avoid deformation
                loading="eager" // force to load in safari
              />
            </PhotoWrapper>
          );
        })}
      </AutoPlayPhotos>
    ) : null}
  </>
);

const Photos = ({ className = "" }: { className?: string }) => {
  const { height, containerRef } = useContainerHeight();
  const { photos } = usePhotos();

  return (
    <div ref={containerRef} className={`flex w-screen overflow-hidden ${className}`}>
      {/* have two instances to implement infinte auto scrolling */}
      <Photo photos={photos} height={height} />
      <Photo photos={photos} height={height} />
    </div>
  );
};

export { Photos };
