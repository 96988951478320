import React from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";
import { Photos } from "./Photos";
import BgImg from "./background";

import COLOR from "constants/design";
import { HeaderPlaceholder } from "components/Header";

const Hero = () => (
  <Section background={{ color: COLOR.BACKGROUND_DARK, component: BgImg }}>
    <div
      className="
    flex w-full flex-col items-center justify-center xl:h-screen xl:min-h-[60rem]"
    >
      <HeaderPlaceholder className="xl:hidden" />
      <div className="flex min-h-max flex-none flex-col pt-5 pb-12 text-center md:py-24 xl:-translate-y-8 xl:p-0">
        <Title className="items-center">
          <FormattedMessage id="TEAM_HERO_TITLE" />
        </Title>
        <div className="m-auto  w-4/5 md:w-[50rem] xl:w-[60rem]">
          <p
            className="
          mt-4 mb-5 whitespace-pre-line text-center text-white 
          md:text-xl md:leading-loose xl:mt-7 xl:mb-10 xl:text-xl"
          >
            <FormattedMessage id="TEAM_HERO_DESCRIPTION" />
          </p>
        </div>

        <Photos className="h-40 xs:h-64 sm:h-96" />
      </div>
    </div>
  </Section>
);

export default Hero;
