import React, { FC, HTMLProps, memo } from "react";

import { useMemberPhotoWidth } from "./hooks";

import type { Leadership } from "../hooks";
import { MemberCard } from "../MemberCard";

const CardContainer: FC<HTMLProps<HTMLDivElement>> = ({ children }) => (
  <div className="mt-5 grid grid-cols-1 gap-y-2 text-white md:grid-cols-2 md:gap-x-12 md:gap-y-9 xl:mt-20 xl:grid-cols-3">
    {children}
  </div>
);

interface MembersProps {
  leadership: Leadership;
  width: number;
}

const Members: FC<MembersProps> = memo(({ leadership, width }) => (
  <CardContainer>
    {leadership.map((props) => (
      <MemberCard {...props} key={props.name} width={width} />
    ))}
  </CardContainer>
));

interface MemberListProps {
  leadership: Leadership;
}

const MemberList: FC<MemberListProps> = ({ leadership }) => {
  const width = useMemberPhotoWidth();
  return <Members leadership={leadership} width={width} />;
};

export { MemberList };
