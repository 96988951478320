import React from "react";
import BgImg from "assets/about_member_bg.inline.svg";

const Image = (props: React.ComponentProps<"div">) => (
  <div {...props} className={`${props.className} relative overflow-hidden`}>
    <BgImg
      preserveAspectRatio="xMaxYMin slice"
      className="
        absolute 
        left-1/2 top-1/2 w-[150%] -translate-x-1/2 -translate-y-1/2 -rotate-12 
        md:left-0 md:top-[6rem] md:w-full md:translate-x-0 md:translate-y-0 md:rotate-0 xl:bottom-0 xl:top-[10rem]"
    />
  </div>
);

export default Image;
