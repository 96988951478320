import React, { ElementType, forwardRef, ReactNode } from "react";
interface TitleProps {
  as?: ElementType;
  children: ReactNode;
  className?: string;
  subTitle?: string | JSX.Element;
  titleClass?: string;
}

const Title = forwardRef<HTMLDivElement, TitleProps>(
  ({ as: Tag = "h2", children, className, subTitle, titleClass }, ref) => {
    const underlineSpace = subTitle ? "xl:mt-1" : "mt-0 xl:mt-2";
    return (
      <div
        ref={ref}
        className={`flex w-full flex-col items-start text-white sm:gap-y-3 ${className}`}
      >
        <Tag className={`text-3xl font-bold sm:text-5xl xl:left-auto ${titleClass}`}>
          {children}
        </Tag>
        {subTitle && <span className="font-bold sm:text-2xl">{subTitle}</span>}
        {/*Underline*/}
        <div className={`mt-3 w-20 border-b-2 border-primary sm:border-b-4 ${underlineSpace}`} />
      </div>
    );
  }
);

export { Title };
