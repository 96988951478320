import { useEffect, useRef, useState, useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useBreakpoint } from "hooks/useBreakpoint";

import type { TeamPhotoData } from "interface/sanityNode";

export const useContainerHeight = () => {
  const [height, setHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (!breakpoint) {
      return;
    }
    const h = containerRef.current?.offsetHeight || 0;
    setHeight(h);
  }, [breakpoint]);

  return { height, containerRef };
};

type Photos = {
  allSanityPhoto: {
    nodes: Array<TeamPhotoData>;
  };
};

const photosQuery = graphql`
  query GetAllTeamPhotos {
    allSanityPhoto {
      nodes {
        _rawPhotoList
      }
    }
  }
`;

export const usePhotos = () => {
  const data = useStaticQuery<Photos>(photosQuery);

  const { photos } = useMemo(() => {
    const { allSanityPhoto } = data;
    return {
      photos: allSanityPhoto.nodes[0]._rawPhotoList.map((item) => item.asset._ref),
    };
  }, [data]);

  return { photos };
};
