import React, { FC } from "react";

import { SanityImage } from "components/SanityImage";
import { SlideUpWrapper } from "./SlideUpWrapper";

export interface MemberCardProps {
  imgUrl: string;
  name: string;
  role?: string;
  width: number;
}

const MemberCard: FC<MemberCardProps> = ({ imgUrl, name, role, width }) => {
  return (
    <div className="relative">
      <SlideUpWrapper>
        <SanityImage
          width={width}
          className="inline-block h-14 w-14 rounded-full bg-btq-bg-light xs:h-16 xs:w-16 sm:h-20 sm:w-20  xl:mb-7 xl:h-44 xl:w-44"
          _ref={imgUrl}
          alt="1"
        />
        <div className="flex flex-col">
          {/* name & position for desktop */}
          <span className="text-lg font-semibold leading-6 text-primary xl:text-center">
            {name}
          </span>
          <span className="xl:text-center">{role}</span>
        </div>
      </SlideUpWrapper>
    </div>
  );
};

export { MemberCard };
