import React from "react";
import BgImg from "assets/about_hero_bg.inline.svg";

const Image = (props: React.ComponentProps<"div">) => (
  <div {...props} className={`${props.className} relative h-full w-full overflow-hidden`}>
    <BgImg
      preserveAspectRatio="xMaxYMid slice"
      className="
        absolute bottom-[4%] right-[13%] aspect-[1799/1043] h-[80%] translate-x-[35%] 
        xl:bottom-12 xl:right-[25%] xl:h-[calc(100%-6rem)]"
    />
  </div>
);

export default Image;
