import React from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";

import MapImg from "assets/about_office_map.inline.svg";
import design from "constants/design";

const Map = () => (
  <Section background={{ imgAdditionalStyle: design.GRADIENT_GROUP_A }}>
    <div className="pt-3 pb-8 xl:pt-20 xl:pb-14">
      <Title className="items-center">
        <FormattedMessage id="TEAM_MAP_TITLE" />
      </Title>
      <MapImg className="mt-2 flex w-full justify-center xl:h-[50vh]" />
    </div>
  </Section>
);

export default Map;
