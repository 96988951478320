import { useRef, useCallback, useLayoutEffect } from "react";
import { useBreakpoint } from "./useBreakpoint";

import { useDebounce } from "./useDebounce";

const useDebounceResizing = (resizingHandler: (breakpoint: number) => void) => {
  const breakpoint = useBreakpoint();
  const prevBreakpoint = useRef<number>(0);

  const resize = useCallback(() => {
    if (breakpoint === prevBreakpoint.current) return;
    prevBreakpoint.current = breakpoint;
    resizingHandler(breakpoint);
  }, [breakpoint, resizingHandler]);

  const debounceResizing = useDebounce(resize, 300);

  useLayoutEffect(() => {
    if (!breakpoint) {
      return;
    }
    debounceResizing();
  }, [breakpoint, debounceResizing]);
};

export { useDebounceResizing };
