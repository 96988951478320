import React from "react";
import BgImg from "assets/about_weAreHiring_bg.inline.svg";

const Image = (props: React.ComponentProps<"div">) => (
  <div {...props} className={`${props.className} flex overflow-hidden`}>
    <div className="w-1/2 shrink-0 grow">{/* spacing */}</div>
    <BgImg className="h-full flex-none translate-x-[3%] translate-y-[10%]" />
  </div>
);

export default Image;
