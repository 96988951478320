import { useState, useCallback } from "react";

import { screensWithoutPx as screens } from "hooks/useBreakpoint";
import { useDebounceResizing } from "hooks/useDebounceResizing";

const widthValues = {
  [screens["2xl"]]: 176,
  [screens.xl]: 176,
  [screens.lg]: 80,
  [screens.md]: 80,
  [screens.sm]: 80,
  [screens.xs]: 64,
  [screens.xxs]: 56,
};

export const useMemberPhotoWidth = () => {
  const [width, setWidth] = useState<number>(0);
  const resizePhoto = useCallback((breakpoint: number) => {
    setWidth(widthValues[breakpoint]);
  }, []);

  useDebounceResizing(resizePhoto);

  return width;
};
