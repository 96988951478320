import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

import type { LANGUAGE_CODE } from "website-metadata";
import type { MemberData } from "interface/sanityNode";
import { getCmsLocaleContent } from "utils/cms";

type MembersQuery = {
  allSanityLeadership: {
    nodes: Array<MemberData>;
  };
  allSanityAdvisor: {
    nodes: Array<MemberData>;
  };
};

const membersQuery = graphql`
  query GetAllMember {
    allSanityLeadership(sort: { order: ASC, fields: order }) {
      nodes {
        _rawMemberDescription
        _rawMemberName
        _rawPhoto
        _rawMemberPosition
      }
    }

    allSanityAdvisor(sort: { order: ASC, fields: order }) {
      nodes {
        _rawMemberDescription
        _rawMemberName
        _rawPhoto
      }
    }
  }
`;

export type Member = {
  description: string;
  name: string;
  imgUrl: string;
  role: string | undefined;
};

export type Leadership = Array<Member>;
export type Advisor = Array<Member>;

const handleContentToMember = (nodes: Array<MemberData>, locale: LANGUAGE_CODE) =>
  nodes.map(
    ({ _rawMemberDescription, _rawMemberName, _rawPhoto, _rawMemberPosition }) =>
      ({
        description: getCmsLocaleContent(_rawMemberDescription, locale),
        name: getCmsLocaleContent(_rawMemberName, locale),
        imgUrl: _rawPhoto.asset._ref,
        role: _rawMemberPosition ? getCmsLocaleContent(_rawMemberPosition, locale) : undefined,
      } as Member)
  );

type Members = {
  leadership: Leadership;
  advisor: Advisor;
};

export const useMembers = (locale: LANGUAGE_CODE) => {
  const data = useStaticQuery<MembersQuery>(membersQuery);

  const { leadership, advisor } = useMemo<Members>(
    () => ({
      leadership: handleContentToMember(data.allSanityLeadership.nodes, locale),
      advisor: handleContentToMember(data.allSanityAdvisor.nodes, locale),
    }),
    [data, locale]
  );

  return { leadership, advisor };
};
